import * as React from "react"
import { Row, Col, Carousel, Card } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import { StyledCarousel, CardPrice, ProductInfo } from "../styles/styles"

import Seo from "../components/seo"

const Estantes = () => {
  const data = useStaticQuery(graphql`
    query {
      allDataJson {
        edges {
          node {
            estantes {
              id
              name
              details
              price
              images {
                childImageSharp {
                  gatsbyImageData(
                    width: 414
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    quality: 100
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Seo title="Estantes - JVM Aço" />

      <ProductInfo>
        <p>Coloridos com 15% de acréscimo. Opções de chapas 26, 24 e 22</p>
        <p>
          Temos estantes com opções de profundidade de 30, 42, 50 e 58. E também
          opções com 5 ou 6 bandejas
        </p>
        <p>
          Parcelamos em até 12x (consulte acréscimo). Entregamos com frete
          (consulte valores)
        </p>
      </ProductInfo>

      <Row className="gy-5">
        {data.allDataJson.edges.map(item => {
          return item.node.estantes.map(product => (
            <Col lg="4" sm="6" xs="12" key={product.id}>
              <Card>
                <StyledCarousel indicators={false} interval={null}>
                  {product.images.map((img, index) => {
                    const image = getImage(img)
                    return (
                      <Carousel.Item key={index}>
                        <GatsbyImage image={image} alt={product.name} />
                      </Carousel.Item>
                    )
                  })}
                </StyledCarousel>

                <CardPrice>{product.price}</CardPrice>

                <Card.Body>
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text as="ul">
                    {product.details.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))
        })}
      </Row>
    </>
  )
}

export default Estantes
